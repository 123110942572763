import { StaticQuery, graphql } from 'gatsby';
import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import CompanyAddress from 'src/components/atoms/CompanyInfo/CompanyAddress/CompanyAddress';
import CompanyName from 'src/components/atoms/CompanyInfo/CompanyName/CompanyName';
import ContactNumber from 'src/components/atoms/CompanyInfo/ContactNumber/ContactNumber';
import GeneralEmail from 'src/components/atoms/CompanyInfo/GeneralEmail/GeneralEmail';
import OpeningHours from 'src/components/atoms/CompanyInfo/OpeningHours/OpeningHours';

import styles from './CompanyInfo.module.scss';

interface IPureCompanyInfoProps extends ICompanyInfoProps {
  allWordpressAcfOptions: any;
}

interface ICompanyInfoProps {
  className?: string;
  showEmail?: boolean;
  showName?: boolean;
  showAddress?: boolean;
  showHours?: boolean;
  showNumber?: boolean;
  block?: boolean;
  showSlogan?: boolean;
}

export const PureCompanyInfo: FunctionComponent<IPureCompanyInfoProps> = ({
  className,
  showEmail = false,
  showName = true,
  showAddress = false,
  showHours = false,
  block = true,
  showNumber = false,
  showSlogan = true,
  allWordpressAcfOptions,
}) => {
  const options = allWordpressAcfOptions.edges[0].node.options;

  return (
    <div
      data-test="component-company-info"
      className={classNames(styles.companyInfo, className)}
    >
      {showName && (
        <CompanyName
          className={styles.companyInfoName}
          name={options.company_name}
          slogan={options.company_slogan}
          showSlogan={showSlogan}
        />
      )}

      {showAddress && (
        <CompanyAddress
          className={styles.companyInfoAddress}
          address={options.address}
        />
      )}

      {showHours && <OpeningHours className={styles.companyInfoOpeningHours} />}

      {showNumber && <ContactNumber contactNumber={options.contact_number} />}

      {showEmail && (
        <GeneralEmail
          className={classNames(styles.companyInfoEmail, {
            [styles.companyInfoBlock]: block,
          })}
          email={options.general_email}
        />
      )}
    </div>
  );
};

const CompanyInfo: FunctionComponent<ICompanyInfoProps> = ({
  className,
  ...props
}) => (
  <StaticQuery
    data-test="component-company-info-query"
    query={query}
    render={queryProps => (
      <PureCompanyInfo {...queryProps} {...props} className={className} />
    )}
  />
);

const query = graphql`
  {
    allWordpressAcfOptions {
      edges {
        node {
          options {
            address
            company_name
            company_slogan
            contact_number
            general_email
          }
        }
      }
    }
  }
`;

export default CompanyInfo;
