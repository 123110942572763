import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Heading from 'src/components/shared/Heading/Heading';

import styles from '../CompanyInfo.module.scss';

interface ICompanyName {
  className?: string;
  name: string;
  slogan: string;
  showSlogan: boolean;
}

const CompanyName: FunctionComponent<ICompanyName> = ({
  className,
  name,
  slogan,
  showSlogan,
}) => {
  return (
    <div data-test="component-company-name" className={classNames(className)}>
      <Heading className={styles.companyInfoHeading} priority={4}>
        {name}
      </Heading>
      {showSlogan && <span className={styles.companyInfoSlogan}>{slogan}</span>}
    </div>
  );
};

export default CompanyName;
