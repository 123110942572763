import React, { FunctionComponent } from 'react';

interface ICompanyAddress {
  className?: string;
  address: string;
}

const CompanyAddress: FunctionComponent<ICompanyAddress> = ({
  className,
  address,
}) => {
  return (
    <address
      data-test="component-company-address"
      className={className}
      dangerouslySetInnerHTML={{ __html: address }}
    />
  );
};

export default CompanyAddress;
