import React, { FunctionComponent } from 'react';
import AnchorLink from 'src/components/atoms/AnchorLink/AnchorLink';
import { formatNumber } from 'src/utils';
import styles from '../CompanyInfo.module.scss';

interface IContactNumberProps {
  className?: string;
  contactNumber: string;
}

const ContactNumber: FunctionComponent<IContactNumberProps> = ({
  contactNumber,
}) => {
  return (
    <p
      data-test="component-contact-number"
      className={styles.companyInfoNumber}
    >
      <strong>Tel:</strong>{' '}
      <AnchorLink
        data-test="contact-number"
        link={`tel:0${contactNumber}`}
        className={styles.companyInfoLink}
        behaviour={'anchor'}
      >
        +44 (0){formatNumber(contactNumber)}
      </AnchorLink>
    </p>
  );
};

export default ContactNumber;
