import React, { FunctionComponent } from 'react';
import AnchorLink from 'src/components/atoms/AnchorLink/AnchorLink';
import styles from '../CompanyInfo.module.scss';

interface IGeneralEmail {
  className?: string;
  email: string;
}

const GeneralEmail: FunctionComponent<IGeneralEmail> = ({ email }) => {
  return (
    <p data-test="component-general-email" className={styles.companyInfoEmail}>
      <strong>Email:</strong>{' '}
      <AnchorLink
        data-test="general-email"
        link={`mailto:${email}`}
        className={styles.companyInfoLink}
        behaviour={'anchor'}
      >
        {email}
      </AnchorLink>
    </p>
  );
};

export default GeneralEmail;
