import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import ContentContactForm from 'src/components/organisms/ContentContactForm/ContentContactForm';
import PageHero from 'src/components/organisms/PageHero/PageHero';
import Testimonials from 'src/components/organisms/Testimonials/Testimonials';
import * as coreActions from '../store/core/actions';
import CoreLayout from 'src/layouts/CoreLayout/CoreLayout';
import {
  IAcfModule,
  IAppState,
  IMenuOpen,
  IPageContext,
  IStateDispatch,
} from 'src/utils/interfaces';

export class UnconnectedContactPage extends PureComponent<
  Partial<IPageContext>
> {
  async componentDidMount(): Promise<void> {
    const { onGetSiteMeta, onGetSiteOptions } = this.props;
    await onGetSiteMeta();
    await onGetSiteOptions();
  }

  render() {
    const { location } = this.props;

    return (
      <>
        <CoreLayout location={location} title="Contact">
          <PageHero
            module={pageHeroModule}
            pageTheme={'brand'}
            image={pageHeroImage}
            location={location}
          />

          <ContentContactForm />

          <Testimonials pageTheme={'tint-beta'} />
        </CoreLayout>
      </>
    );
  }
}

const pageHeroModule: Partial<IAcfModule> = {
  heading: 'Contact us',
  copy: `Let's start something awesome`,
};

const pageHeroImage: string = `${process.env.GATSBY_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-content/uploads/2019/08/light-4297386_1920.jpg`;

export default connect(
  ({ core: { isMenuOpen } }: IMenuOpen) => ({
    isMenuOpen,
  }),
  (dispatch: ThunkDispatch<IAppState, IStateDispatch, AnyAction>) => ({
    onGetSiteMeta: () => dispatch(coreActions.getSiteMeta()),
    onGetSiteOptions: () => dispatch(coreActions.getSiteOptions()),
  })
)(UnconnectedContactPage);
