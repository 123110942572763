import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import CompanyInfo from 'src/components/atoms/CompanyInfo/CompanyInfo';
import Container from 'src/components/grid/Container/Container';
import Flex from 'src/components/grid/Flex/Flex';
import Row from 'src/components/grid/Row/Row';
import Section from 'src/components/grid/Section/Section';
import FormBase, {
  IFormBaseProps,
} from 'src/components/molecules/FormBase/FormBase';

import styles from './ContentContactForm.module.scss';

interface IContentContactFormProps {
  className?: string;
}

const ContentContactForm: FunctionComponent<IContentContactFormProps> = () => {
  return (
    <Section
      theme={'tint-alpha'}
      className={classNames(styles.contentContactForm)}
    >
      <Container fluid={false}>
        <Row>
          <Flex colLg={3}>
            <CompanyInfo
              className={styles.contentContactFormCompanyInfo}
              showName
              showAddress
              showHours
              showNumber
              showEmail
              showSlogan={false}
            />
          </Flex>

          <Flex colLg={8} className={classNames('ml-lg-auto')}>
            <p className={classNames(styles.contentContactForm__copy, 'mb-2')}>
              Fill in the project planner below and give us a little insight
              into your project and what you require.
            </p>

            <FormBase
              formName={setForm.formName}
              buttons={setForm.buttons}
              formGroups={setForm.formGroups}
              successButtonTheme={'tint-omega'}
              loadingTheme={'brand'}
              centerButtons={false}
            />
          </Flex>
        </Row>
      </Container>
    </Section>
  );
};

const setForm: Partial<IFormBaseProps> = {
  formName: 'main-contact-form',
  buttons: [
    {
      type: 'submit',
      text: 'Let\'s start something',
      theme: 'brand',
      size: 'lg',
    },
  ],
  formGroups: [
    {
      fieldSet: {
        fields: [
          {
            type: 'input',
            columns: {
              colMd: 6,
            },
            input: {
              label: 'First Name',
              name: 'first-name',
              type: 'text',
              placeholder: 'Enter your first name',
            },
          },
          {
            type: 'input',
            columns: {
              colMd: 6,
            },
            input: {
              label: 'Last Name',
              name: 'last-name',
              type: 'text',
              placeholder: 'Enter your last name',
            },
          },
          {
            type: 'input',
            columns: {
              colMd: 6,
            },
            input: {
              label: 'Email',
              name: 'email',
              type: 'email',
              placeholder: 'Enter your email address',
            },
          },
          {
            type: 'input',
            columns: {
              colMd: 6,
            },
            input: {
              label: 'Contact Number',
              name: 'contact-number',
              type: 'text',
              min: 0,
              max: 21,
              placeholder: 'Enter your contact number',
            },
          },
          {
            type: 'select',
            columns: {
              colMd: 6,
            },
            input: {
              label: 'Service Required',
              name: 'service-required',
              placeholder: 'Please select an option',
              type: 'select',
              options: [
                {
                  name: 'Website Development',
                  value: 'Website Development',
                },
                {
                  name: 'Graphic Design',
                  value: 'Graphic Design',
                },
                {
                  name: 'Branding',
                  value: 'Branding',
                },
                {
                  name: 'Hosting',
                  value: 'Hosting',
                },
                {
                  name: 'SEO',
                  value: 'SEO',
                },
              ],
            },
          },
          {
            type: 'select',
            columns: {
              colMd: 6,
            },
            input: {
              label: 'What is your budget?',
              name: 'budget',
              placeholder: 'Please select an option',
              type: 'select',
              options: [
                {
                  name: 'I don\'t know',
                  value: 'I don\'t know',
                },
                {
                  name: 'Up to £500',
                  value: '500',
                },
                {
                  name: 'Up to £1500',
                  value: '1500',
                },
                {
                  name: '£1500+',
                  value: '1501+',
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

export default ContentContactForm;
