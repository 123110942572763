import React, { FunctionComponent } from 'react';

interface IOpeningHours {
  className?: string;
}

const OpeningHours: FunctionComponent<IOpeningHours> = ({ className }) => {
  return (
    <p data-test="component-opening-hours" className={className}>
      <strong>Opening Hours:</strong>
      <br />
      Monday - Friday: 9:00am - 5:00pm
      <br />
      Saturday - Sunday: Closed
    </p>
  );
};

export default OpeningHours;
